module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Luis Alberto Inga Rivera","short_name":"Luis","start_url":"/","background_color":"#6b37bf","theme_color":"#6b37bf","display":"standalone","icon":"static/favicon.ico","icon_options":{"purpose":"any"},"icons":[{"src":"src/images/maskable_icon_x48.png","sizes":"48x48","type":"image/png","purpose":"any"},{"src":"src/images/maskable_icon_x192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"src/images/maskable_icon_x512.png","sizes":"512x512","type":"image/png","purpose":"any"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"43e5d0f06afbf40d122151e5c9ea8772"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"appendScript":"src/custom-sw-code.js","workboxConfig":{"importWorkboxFrom":"cdn"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
